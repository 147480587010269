<template>
  <v-slide-group show-arrows v-model="activeCategory">
    <v-slide-item v-for="category in categoryList" :key="category.id">
      <button
        v-if="!isCountry"
        class="categoryListItem"
        :class="
          activeCategory == category?.translations?.slug
            ? 'categoryListItemActive'
            : ''
        "
        depressed
        rounded
        @click="activeCategory = category?.translations?.slug"
      >
        {{ category?.translations?.name }}
      </button>
      <button
        v-else
        class="categoryListItem"
        :class="
          activeCategory == category.iso2code ? 'categoryListItemActive' : ''
        "
        depressed
        rounded
        @click="activeCategory = category?.iso2code"
      >
        {{ category?.translations?.name }}
      </button>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    activeCategory: 0,
  }),
  props: {
    categoryList: {
      require: true,
    },
    isCountry: {
      require: false,
    },
  },
  mounted() {
    if (this.isCountry) {
      setTimeout(() => {
        this.activeCategory = this.categoryList?.[0]?.iso2code;
      }, 200);
    } else {
      this.activeCategory = this.categoryList?.[0]?.translations.slug;
    }
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  watch: {
    activeCategory: {
      handler() {
        this.$emit("setCategory", this.activeCategory);
      },
    },
    categoryList: {
      deep: true,
      handler() {
        this.activeCategory = this.categoryList?.[0]?.translations.slug;
      },
    },
  },
};
</script>

<style scoped>
.categoryListItem {
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  background: #fcfcfc;
  display: grid;
  place-items: center;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 20px;
  padding: 8px 12px;
  user-select: none;
}
.categoryListItem:hover {
  color: #fafafa;
  background: #085895;
}
.categoryListItemActive {
  color: #fafafa;
  background: #085895;
  box-shadow: 0px 4px 4px 0px rgba(20, 158, 81, 0.1);
}
</style>
<template>
  <routes-component />
</template>

<script>
import routesComponent from "@/components/User/Routes/RoutesComponent.vue";
export default {
  components: { routesComponent },
  mounted(){
    this.$vuetify.goTo(0,0)
  },
};
</script>

<style>
</style>
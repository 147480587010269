<template>
  <v-col class="px-0 py-0" style="margin-bottom: 70px">
    <div class="cardBackround">
      <v-main>
        <v-container>
          <page-step
            :pages="[{ id: 1, name: 'Наші маршрути', link: '/our_routes' }]"
          />
          <v-row no-gutters justify="center">
            <search-ticket-form style="margin-top: 20px" />
          </v-row>
        </v-container>
      </v-main>
    </div>
    <Loader v-if="showLoader" />
    <v-col class="px-0 py-0" v-else>
      <popular-route-list
        v-if="popularRouteList.length"
        :routeList="popularRouteList"
        style="margin-top: 70px; margin-bottom: 70px"
      />
      <v-main>
        <v-container>
          <v-row no-gutters align="center">
            <div
              style="
                width: 12px;
                height: 12px;
                background: #144fa9;
                border-radius: 50%;
              "
            />
            <h2
              style="
                color: var(--Black, #1b1b1b);
                font-family: 'MacPaw Fixel';
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-left: 8px;
              "
            >
              {{ "our_routes_section" | localize }}
            </h2>
          </v-row>
          <category-list
            :categoryList="countryList"
            :isCountry="true"
            @setCategory="setActiveCountry"
            style="margin-top: 40px"
          />
          <Loader v-if="showLoaderRoutes" />
          <v-row v-else no-gutters align="center" style="margin-top: 40px">
            <v-col
              cols="12"
              xl="6"
              lg="6"
              md="6"
              sm="12"
              v-for="(route, index) in routesList"
              :key="route.id"
              style="margin-bottom: 20px"
              :style="
                $vuetify.breakpoint.smAndDown
                  ? ''
                  : index % 2 == 1
                  ? 'padding-right: 10px;'
                  : 'padding-left: 10px'
              "
            >
              <route-card :route="route" />
            </v-col>
          </v-row>
          <v-row no-gutters justify="center">
            <submit-outline-button
              style="margin-top: 40px; width: 180px"
              v-if="quantityPage > 1"
              >Завантажити ще</submit-outline-button
            >
          </v-row>
        </v-container>
      </v-main>
    </v-col>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import routesService from "../../../requests/Admin/routesService";
import SubmitOutlineButton from "../../UI/Buttons/submitOutlineButton.vue";
import RouteCard from "../../UI/Cards/routeCard.vue";
import SearchTicketForm from "../../UI/Forms/searchTicketForm.vue";
import CategoryList from "../../UI/Lists/categoryList.vue";
import PopularRouteList from "../../UI/Lists/popularRoutes/popularRouteList.vue";
import Loader from "../../UI/Loader.vue";
import pageStep from "../../UI/pageStep.vue";
export default {
  components: {
    pageStep,
    SearchTicketForm,
    PopularRouteList,
    RouteCard,
    CategoryList,
    SubmitOutlineButton,
    Loader,
  },
  data: () => ({
    countryList: [],
    routesList: [],
    popularRouteList: [],
    page: 1,
    quantityPage: 0,
    showLoader: true,
    showLoaderRoutes: false,
  }),
  mounted() {
    this.getCountries();
    this.getRoutes();
    this.getPopularRoutes();
  },
  methods: {
    setActiveCountry(country_iso2_code) {
      this.showLoaderRoutes = true;
      this.getRoutes(country_iso2_code);
    },
    async getCountries() {
      await routesService.getCountriesForMain().then((res) => {
        if (res.status == "Success") {
          this.countryList = res.data;
          this.countryList.unshift({
            id: 1,
            iso2code: "All",
            translations: {
              name: "Усі",
            },
          });
        }
      });
    },
    async getRoutes(country_iso2_code) {
      await routesService
        .getRoutes(this.page, country_iso2_code)
        .then((res) => {
          if (res.status == "Success") {
            this.routesList = res.data;
            this.quantityPage = res?.pagination?.meta?.last_page;
            this.showLoader = false;
            this.showLoaderRoutes = false;
          }
        });
    },
    async getPopularRoutes() {
      await routesService.getPopularRoutes().then((res) => {
        if (res.status == "Success") {
          this.popularRouteList = res.data.routes;
        }
      });
    },
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  watch: {
    locale: {
      handler() {
        this.showLoader = true;
        this.getCountries();
        this.getRoutes();
        this.getPopularRoutes();
      },
    },
  },
};
</script>

<style scoped>
.cardBackground {
  padding: 20px 0px;
  background: #fff;
}
</style>
<template>
  <v-row no-gutters align="center">
    <router-link to="/" style="margin-top: 5px">
      <img src="@/assets/img/iconsSvg/homeIcon.svg" alt="home icon"
    /></router-link>
    <div
      v-for="page in pages"
      :key="page.id"
      style="
        margin-left: 8px;
        width: max-content;
        display: flex;
        align-items:center;
      "
    >
      <v-icon color="#4B5262"
        >mdi-chevron-right</v-icon
      >
      <router-link :to="page.link" style="margin-top: 2px;">
        <p class="pageName">{{ page.name }}</p>
      </router-link>
    </div>
  </v-row>
</template>

<script>
export default {
  props: {
    pages: {
      require: true,
    },
  },
};
</script>

<style scoped>
.pageName {
  color: #989898;
  font-family: "MacPaw Fixel Display";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: max-content;
  max-width: 200px;
  margin: 0px;
}
</style>
<template>
  <div
    :class="['popularRouteCard', { 'popularRouteCard--hover': isHover }]"
    :style="cardStyle"
    @mousemove="isHover = true"
    @mouseleave="isHover = false"
    @click="
      $vuetify.breakpoint.smAndDown
        ? $router.push(`/route/${route.slug}/${route.id}`)
        : ''
    "
  >
    <div style="padding: 16px 0px 0px 16px">
      <div
        style="
          border-radius: 10px;
          background: #fed500;
          display: grid;
          place-items: center;
          color: #172b69;
          font-family: 'MacPaw Fixel';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          width: max-content;
          padding: 4px 8px;
        "
      >
        {{ "popular_label" | localize }}
      </div>
      <p class="routeName">
        {{ route?.departure?.translations?.name }}-{{
          route?.destination?.translations?.name
        }}
      </p>
      <p class="routePrice">
        {{
          route?.prices[currency.value].length
            ? route?.prices[currency.value][
                route?.prices[currency.value].length - 1
              ]?.price
            : route?.prices.UAH[
                route?.prices.UAH.length - 1
              ]?.price
        }}
        {{  route?.prices[currency.value].length ? currency.text : 'UAH' }}
      </p>
      <v-expand-transition duration="150">
        <v-btn
          height="40px"
          class="detailBtn"
          v-if="isHover && !$vuetify.breakpoint.smAndDown"
          @click="$router.push(`/route/${route.slug}/${route.id}`)"
          >{{ "detail_btn" | localize }}</v-btn
        >
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex/dist/vuex.common.js";

export default {
  data: () => ({
    isHover: false,
  }),
  props: {
    route: {
      require: true,
    },
  },
  computed: {
    ...mapGetters(["currency"]),
    cardStyle() {
      if (this.isHover && !this.$vuetify.breakpoint.smAndDown) {
        return {
          background: `linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) -3.27%,
            rgba(0, 0, 0, 0) 165.41%
          ), url(${this.route.image.path})`,
          backgroundSize: "cover",
        };
      } else {
        return {
          backgroundImage: `url(${this.route.image.path})`,
          backgroundSize: "cover",
        };
      }
    },
  },
};
</script>

<style scoped>
.popularRouteCard {
  height: 320px;
  border-radius: 10px;
  transition: background 0.3s;
}
@media only screen and (min-width: 1000px) {
  .popularRouteCard--hover {
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) -3.27%,
        rgba(0, 0, 0, 0) 165.41%
      ),
      lightgray 50% / cover no-repeat;
    background-size: cover;
  }
  .popularRouteCard:hover .routeName {
    bottom: 82px;
  }
  .popularRouteCard:hover .routePrice {
    bottom: 60px;
  }
}
.routeName {
  color: var(--Neutrals, #fff);
  font-family: "MacPaw Fixel";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  bottom: 22px;
}
.routePrice {
  color: #fff;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  bottom: 0px;
}
.detailBtn {
  width: 75%;
  border-radius: 10px;
  background: #144fa9 !important;
  text-transform: none;
  color: #fff;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
</style>
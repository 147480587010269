<template>
  <v-col>
    <v-row no-gutters align="center">
      <div
        class="iconBackground swiper-button-prev-popular-routes"
        style="left: 9svw"
        slot="button-prev"
        v-if="!$vuetify.breakpoint.smAndDown"
      >
        <img
          src="@/assets/img/iconsSvg/swiperRightIcon.svg"
          alt="right icon"
          style="transform: rotate(180deg)"
        />
      </div>
      <v-main>
        <v-container>
          <v-row no-gutters align="center">
            <div
              style="
                width: 12px;
                height: 12px;
                background: #144fa9;
                border-radius: 50%;
              "
            />
            <v-col class="px-0 py-0">
              <h2
                style="
                  color: var(--Black, #1b1b1b);
                  font-family: 'MacPaw Fixel';
                  font-size: 32px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  margin-left: 8px;
                "
              >
                {{ "popular_routes_section" | localize }}
              </h2>
            </v-col>
          </v-row>
          <v-col class="py-0" style="margin-top: 40px">
            <swiper
              ref="mySwiper"
              v-if="!$vuetify.breakpoint.smAndDown"
              :options="swiperOptions"
            >
              <swiper-slide
                class="custom-slide"
                v-for="route in routeList"
                :key="route.id"
                :cssMode="true"
                :navigation="true"
                :pagination="true"
                :mousewheel="true"
                :keyboard="true"
                :modules="swiperOptions.modules"
              >
                <popular-route-card :route="route" />
              </swiper-slide>
            </swiper>
            <swiper ref="mySwiper" :options="swiperMobileOptions" v-else>
              <swiper-slide
                class="custom-slide"
                v-for="route in routeList"
                :key="route.id"
                :cssMode="true"
                :navigation="true"
                :pagination="true"
                :mousewheel="true"
                :keyboard="true"
                :modules="swiperMobileOptions.modules"
              >
                <popular-route-card :route="route" />
              </swiper-slide>
            </swiper>
          </v-col>
        </v-container>
      </v-main>

      <div
        style="right: calc(9svw - 7px)"
        class="iconBackground swiper-button-next-popular-routes"
        slot="button-next"
        v-if="!$vuetify.breakpoint.smAndDown"
      >
        <img src="@/assets/img/iconsSvg/swiperRightIcon.svg" alt="right icon" />
      </div>
    </v-row>
  </v-col>
</template>
  
  <script>
import { Pagination, Navigation } from "vue-awesome-swiper";
import popularRouteCard from "./popularRouteCard.vue";
export default {
  components: { popularRouteCard },
  data: () => ({
    swiperOptions: {
      slidesPerView: 4,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next-popular-routes",
        prevEl: ".swiper-button-prev-popular-routes",
      },
      modules: [Navigation, Pagination],
    },
    swiperMobileOptions: {
      slidesPerView: 1.2,
      spaceBetween: 20,
      navigation: {
        nextEl: ".swiper-button-next-popular-routes",
        prevEl: ".swiper-button-prev-popular-routes",
      },
      modules: [Navigation, Pagination],
    },
  }),
  props: {
    routeList: { require: true },
  },
};
</script>
  
  <style scoped>
.iconBackground {
  width: 48px;
  height: 48px;
  display: grid;
  place-items: center;
  background: #fafafa;
  box-shadow: -12px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  position: absolute;
  z-index: 10;
  cursor: pointer;
  user-select: none;
  margin-top: 60px;
}
</style>